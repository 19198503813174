import { useEffect, useState, useRef } from "react";
import { get, update, push } from "firebase/database";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import { useStore } from "react-redux";
import { useFirebase } from "../../../app/firebase/context";
import React from "react";
import "font-awesome/css/font-awesome.min.css";
import { useParams } from "react-router-dom";
import { Breakpoint } from "react-socks";
import UpdateIcon from "@material-ui/icons/Update";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Dialogo } from "app/components/helpers/Dialogo";
import useAuth from "app/hooks/useAuth";
import {
  Paper,
  Box,
  Grid,
  Backdrop,
  MenuItem,
  Checkbox,
  Button,
  FormControlLabel,
} from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import { getJunta } from "app/views/juntas/FuncionesJuntas";
import { getNombreComunidad } from "app/views/comunidad/FuncionesComunidades";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { toast } from "react-toastify";
import { signInWithEmailAndPassword, getAuth, onAuthStateChanged } from "firebase/auth";
import { validarDNI, validarEmail } from "app/views/usuarios/FuncionesUsuarios";
import { id } from "date-fns/locale";
import { set } from "lodash";
import { obtenerMetas } from "app/components/helpers/Various";

const FormularioAsistenciaQR = () => {
  const [encontrado, setEncontrado] = useState(false);
  const { user } = useAuth();
  const [comprobrar, setComprobar] = useState(false);
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const [loading, setLoading] = useState(true);
  const [darDeAlta, setDarDeAlta] = useState(false);
  const { IdComu, Identificador, BaseDatos } = useParams();
  const firebase = useFirebase();
  const functions = getFunctions(firebase.app, process.env.REACT_APP_FIREBASE_ZONE);
  const navigate = useNavigate();
  const [listaEstados, setListaEstados] = useState([]);
  const [listaEstadosSelected, setListaEstadosSelected] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const initialState = {
    Comunidad: "",
    Titulo: "",
    Numero: "",
    Nombre: "",
    Apellido: "",
    DNI: "",
    Email: "",
    Direcciones: [],
    Observaciones: "",
    Direccion: "",
    D_Numero: "",
    D_Portal: "",
    D_Piso: "",
    D_Letra: "",
    NoSoyPropietario: false,
  };
  const [state, setState] = useState(initialState);
  const changeValue = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const estErroresIni = {
    Nombre: false,
    Apellido: false,
    DNI: false,
    Email: false,
    Direccion: false,
    D_Numero: false,
    D_Portal: false,
    D_Piso: false,
    D_Letra: false,
    Observaciones: false,
  };

  const conectaDespacho = async (bd) => {
    var despacho = [];
    var firebase2 = "";

    const email = process.env.REACT_APP_PUBLIC_USER;
    const password = process.env.REACT_APP_PUBLIC_PASS;

    const auth = getAuth();
    const response = await signInWithEmailAndPassword(auth, email, password);
    var { user } = response;

    const customClaims = await user.getIdTokenResult().then((idTokenResult) => {
      return idTokenResult.claims;
    });
    const connectionInfo = customClaims.connectionInfo ?? null;

    const lengu = store.getState().lenguajes;
    const idiomaSeleccionadoState = lengu.lenguaje;

    // Rescato bd admin
    const bddAdm = process.env.REACT_APP_DATABASE_ADMIN_URL;
    await firebase.changeDatabase(bddAdm);
    let urldb = await get(firebase.getReferencia(`desks/${bd}/url`));
    urldb = urldb.val();

    await firebase.changeDatabase(urldb);
    const id = urldb.split("conecta-c365-")[1].split(".")[0];
    await firebase.changeStorage(`gs://conecta-c365-${id}`);
  };

  const [erroresCamposRequeridos, setErroresCamposRequeridos] = useState(estErroresIni);

  const asistirJunta = async () => {
    try {
      let hasErrors = false;
      const newFormErrors = { ...erroresCamposRequeridos };

      setLoading(true);

      // Validation
      if (state.Nombre.trim() === "") {
        newFormErrors.Nombre = true;
        hasErrors = true;
      } else {
        newFormErrors.Nombre = false;
      }
      if (state.Apellido.trim() === "") {
        newFormErrors.Apellido = true;
        hasErrors = true;
      } else {
        newFormErrors.Apellido = false;
      }

      if (!validarDNI(DNI) && state.DNI.trim() !== "") {
        toast.error(diccionario.NoValidoIntroduceCorrecto, getCustomToastOptions(toast, "error"));
      }

      if (state.DNI.trim() === "" || !validarDNI(state.DNI.trim())) {
        newFormErrors.DNI = true;
        hasErrors = true;
      } else {
        newFormErrors.DNI = false;
      }

      if (!validarEmail(Email) && state.Email.trim() !== "") {
        toast.error(
          diccionario.EmailNoValidoIntroduceCorrecto,
          getCustomToastOptions(toast, "error")
        );
      }

      if (state.Email.trim() === "" || !validarEmail(Email)) {
        newFormErrors.Email = true;
        hasErrors = true;
      } else {
        newFormErrors.Email = false;
      }
      /*
      if (!NoSoyPropietario) {
        if (state.Direccion.trim() === "") {
          newFormErrors.Direccion = true;
          hasErrors = true;
        } else {
          newFormErrors.Direccion = false;
        }
        if (state.D_Numero.trim() === "") {
          newFormErrors.D_Numero = true;
          hasErrors = true;
        } else {
          newFormErrors.D_Numero = false;
        }
      } else {
        if (state.Observaciones.trim() === "") {
          newFormErrors.Observaciones = true;
          hasErrors = true;
        } else {
          newFormErrors.Observaciones = false;
        }
      }
*/
      if (hasErrors) {
        setErroresCamposRequeridos(newFormErrors);
        // Muestro toast de error de validación y salgo de la función
        toast.error(diccionario.ErrorCamposRequeridos, getCustomToastOptions(toast, "error"));
        setLoading(false);
        return 0;
      } else {
        setErroresCamposRequeridos(estErroresIni);
        const refAsistentes = firebase.getReferencia(
          `Comunidad/${IdComu}/Juntas/${Identificador}/Asistentes`,
          null
        );

        const direccionPush =
          Direccion || D_Numero || D_Portal || D_Piso || D_Letra
            ? {
                Calle: Direccion,
                Numero: D_Numero,
                Portal: D_Portal,
                Piso: D_Piso,
                Letra: D_Letra,
                Tipo: "propietario",
              }
            : null;

        // Lo inserto
        const objAsistente = {
          idSesionUsuario: "",
          Nombre: Nombre,
          Apellido: Apellido,
          DNI: DNI,
          Email: Email,
          Direcciones: [direccionPush],
          Observaciones: Observaciones,
          TiempoAcumulado: 0,
          TipoAsistencia: !NoSoyPropietario
            ? "FISICA FORMULARIO"
            : "FISICA FORMULARIO + NO PROPIETARIO",
          Entrada: Date.now(),
          Salida: "",
          Conexiones: 0,
          EsPropietario: NoSoyPropietario ? false : true,
        };
        await push(refAsistentes, objAsistente);
        setLoading(false);
        setState({
          ...state,
          Nombre: "",
          Apellido: "",
          DNI: "",
          Email: "",
          Direcciones: [],
          Observaciones: "",
          Direccion: "",
          D_Numero: "",
          D_Portal: "",
          D_Piso: "",
          D_Letra: "",
          NoSoyPropietario: false,
          TipoAsitencia: NoSoyPropietario
            ? "FISICA FORMULARIO"
            : "FISICA FORMULARIO + NO PROPIETARIO",
        });
        toast.success(diccionario.AsistenciaRegistradaConExito, getCustomToastOptions(toast));
      }
    } catch {
      toast.error(diccionario.ErrorGuardandoAsistencia, getCustomToastOptions(toast, "error"));
    }
  };

  const enviarEmailAlta = async (userId, IdComu) => {
    const functions = getFunctions(firebase.app, process.env.REACT_APP_FIREBASE_ZONE);

    const desk = user["despacho"]["Codigo"];
    // const userId = state["DNI"];

    const sendRegistrationCode = httpsCallable(functions, "sendRegistrationCode");

    new Promise((resolve, reject) => {
      sendRegistrationCode({
        desk: desk,
        user: userId,
        community: IdComu,
      })
        .then((result) => {
          toast.success(diccionario["sendRegistrationCodeSucess"], getCustomToastOptions(toast));

          resolve(true);
        })
        .catch(function (error) {
          toast.error(
            diccionario["sendRegistrationCodeFailure"],
            getCustomToastOptions(toast, "error")
          );

          reject(error);
        });
    });
    setDarDeAlta(false);
  };
  const onSubmit = async (bd) => {};

  const obtenerMetas = async (Identificador) => {
    const obtenerMetadatosUsuario = httpsCallable(functions, "getOwnerMetadata");

    await obtenerMetadatosUsuario({
      id: Identificador,
      mode: "user",
    }).then((data) => {
      const usuarioData = data.data[Identificador];

      if (usuarioData.emailVerified === true) {
        console.log("Usuario verificado", Identificador);
        console.log("usuarioData", usuarioData);
      } else if (usuarioData.emailVerified === false) {
        console.log("Usuario no verificado", Identificador);
        console.log("usuarioData", usuarioData);

        setDarDeAlta(true);
      }
    });
  };

  const {
    Comunidad,
    Titulo,
    Numero,
    Nombre,
    Apellido,
    DNI,
    Email,
    Direcciones,
    Observaciones,
    Direccion,
    D_Numero,
    D_Portal,
    D_Piso,
    D_Letra,
    NoSoyPropietario,
  } = state;

  if (DNI.length === 9 && !comprobrar) {
    let localizado = false;
    listaUsuarios.forEach((usuario) => {
      if (usuario.DNI.toLowerCase() == DNI.toLowerCase()) {
        setEncontrado(true);
        localizado = true;
        setState({
          ...state,
          Nombre: usuario.Nombre,
          Apellido: usuario.Apellido,
          Email: usuario.Email ? usuario.Email : "",
          Direccion:
            usuario.Direcciones.length > 0 && usuario.Direcciones[0].Calle
              ? usuario.Direcciones[0].Calle
              : "",
          D_Numero:
            usuario.Direcciones.length > 0 && usuario.Direcciones[0].Escalera
              ? usuario.Direcciones[0].Escalera
              : "",
          D_Portal:
            usuario.Direcciones.length > 0 && usuario.Direcciones[0].Portal
              ? usuario.Direcciones[0].Portal
              : "",
          D_Piso:
            usuario.Direcciones.length > 0 && usuario.Direcciones[0].Portal
              ? usuario.Direcciones[0].Portal
              : "",
          D_Letra:
            usuario.Direcciones.length > 0 && usuario.Direcciones[0].Letra
              ? usuario.Direcciones[0].Letra
              : "",
        });
      }
    });
    if (localizado === true) {
      obtenerMetas(DNI);
    }

    if (!localizado) {
      alert(
        "Usuario no encontrado en la base de datos de la comunidad, Si no erees propietario marca el check de no soy propietario"
      );
    }
    setComprobar(true);
  }
  if (DNI.length < 9 && comprobrar) {
    setState({
      ...state,
      Nombre: "",
      Apellido: "",
      Email: "",
      Direccion: "",
      D_Numero: "",
      D_Portal: "",
      D_Piso: "",
      D_Letra: "",
    });
    setComprobar(false);
  }

  const traerUsuarios = async () => {
    const snapshot2 = await get(firebase.getReferencia(`Comunidad/${IdComu}`));
    const instancias = snapshot2.val().Instancias;
    let listaUsuarios = [];
    const snapshot = await get(firebase.getReferencia("Usuarios", null));
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      if (childData.Comunidades[IdComu]) {
        let arrDirecciones = [];

        arrDirecciones =
          Array.isArray(instancias) &&
          instancias.filter(
            (instancia) => instancia.Usuario === childKey && instancia.Tipo !== "empleadocomunidad"
          );

        childData.Direcciones = arrDirecciones;

        listaUsuarios.push(childData);
      }
    });
    console.log("listaUsuarios", listaUsuarios);
    return listaUsuarios;
  };

  useEffect(async () => {
    await conectaDespacho(BaseDatos);
    const usus = await traerUsuarios();
    setListaUsuarios(usus);
    const junta = await getJunta(firebase, IdComu, Identificador);
    const nombreComunidad = await getNombreComunidad(firebase, IdComu);

    setState({ ...state, Comunidad: nombreComunidad, Titulo: junta.Titulo, Numero: junta.Numero });
    setLoading(false);
  }, []);

  return (
    <>
      <Dialogo
        showDialog={darDeAlta}
        diagoloBody={
          <div>
            <p>{diccionario.UsuarioNoRegistradoEnApp}</p>
          </div>
        }
        diagoloTitulo={diccionario.NoEstasRegistradoAPP}
        onConfirm={() => enviarEmailAlta(DNI, IdComu)}
        onClose={() => {
          setDarDeAlta(false);
        }}
      />

      <div
        style={{
          width: "100%",
          overflowX: "over",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <img src="/assets/images/comunidad365.png" alt="" />
      </div>
      {loading && (
        <ClipLoader
          size={150}
          color={"#123abc"}
          loading={loading}
          cssOverride={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          speedMultiplier={1}
        />
      )}
      {!loading && (
        <div className="contenedor-comunidades">
          <Grid>
            <div>
              {" "}
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Paper
                  elevation={4}
                  style={{
                    maxWidth: 800,
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <Breakpoint large up>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <h4 style={{ color: "#172983", marginLeft: 20 }}>
                        {diccionario.Comunidad}
                        <p style={{ color: "gray" }}> {Comunidad}</p>{" "}
                      </h4>
                      <h4 style={{ color: "#172983", marginRight: 20 }}>
                        <p style={{ color: "gray" }}>
                          {" "}
                          {Titulo} / {Numero}
                        </p>
                      </h4>
                    </Grid>
                  </Breakpoint>
                  <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      disabled={false}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="DNI"
                      multiline
                      label={diccionario.DNI}
                      name="DNI"
                      autoComplete="DNI"
                      value={DNI}
                      type="text"
                      // placeholder={diccionario.DNI}
                      onChange={changeValue}
                      error={erroresCamposRequeridos.DNI}
                      placeholder={diccionario.AutoRellenarDNI}
                    />
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="Nombre"
                      multiline
                      label={diccionario.Nombre}
                      name="Nombre"
                      autoComplete="Nombre"
                      value={Nombre}
                      type="text"
                      placeholder={diccionario.Nombre}
                      onChange={changeValue}
                      error={erroresCamposRequeridos.Nombre}
                      disabled={NoSoyPropietario == false}
                    />
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      disabled={NoSoyPropietario == false}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="Apellido"
                      multiline
                      label={diccionario.apellidos}
                      name="Apellido"
                      autoComplete="Apellido"
                      value={Apellido}
                      type="text"
                      placeholder={diccionario.apellidos}
                      onChange={changeValue}
                      error={erroresCamposRequeridos.Apellido}
                    />

                    <TextField
                      InputLabelProps={{ shrink: true }}
                      disabled={NoSoyPropietario == false}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="Email"
                      multiline
                      label={diccionario.Email}
                      name="Email"
                      autoComplete="Email"
                      value={Email}
                      type="text"
                      placeholder={diccionario.Email}
                      onChange={changeValue}
                      error={erroresCamposRequeridos.Email}
                    />
                    {encontrado == false || DNI.length < 9 ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={NoSoyPropietario}
                            onChange={(e) => {
                              setState({
                                ...state,
                                NoSoyPropietario: e.target.checked,
                              });
                            }}
                            name="horarioFindes"
                          />
                        }
                        label={diccionario.NoSoyPropietario}
                      />
                    ) : null}
                    {!NoSoyPropietario && (
                      <>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={NoSoyPropietario == false}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="Direccion"
                          multiline
                          label={diccionario.Direccion}
                          name="Direccion"
                          autoComplete="Direccion"
                          value={Direccion}
                          type="text"
                          placeholder={diccionario.Direccion}
                          onChange={changeValue}
                          error={!NoSoyPropietario && erroresCamposRequeridos.Direccion}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={NoSoyPropietario == false}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="D_Numero"
                          multiline
                          label={diccionario.Numero}
                          name="D_Numero"
                          autoComplete="D_Numero"
                          value={D_Numero}
                          type="text"
                          placeholder={diccionario.Numero}
                          onChange={changeValue}
                          error={!NoSoyPropietario && erroresCamposRequeridos.D_Numero}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={NoSoyPropietario == false}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="D_Portal"
                          multiline
                          label={diccionario.Portal}
                          name="D_Portal"
                          autoComplete="D_Portal"
                          value={D_Portal}
                          type="text"
                          placeholder={diccionario.Portal}
                          onChange={changeValue}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={NoSoyPropietario == false}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="D_Piso"
                          multiline
                          label={diccionario.Piso}
                          name="D_Piso"
                          autoComplete="D_Piso"
                          value={D_Piso}
                          type="text"
                          placeholder={diccionario.Piso}
                          onChange={changeValue}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={NoSoyPropietario == false}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="D_Letra"
                          multiline
                          label={diccionario.Letra}
                          name="D_Letra"
                          autoComplete="D_Letra"
                          value={D_Letra}
                          type="text"
                          placeholder={diccionario.Letra}
                          onChange={changeValue}
                        />
                      </>
                    )}
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      disabled={false}
                      variant="outlined"
                      required={NoSoyPropietario}
                      margin="normal"
                      fullWidth
                      id="Observaciones"
                      multiline
                      label={diccionario.Observaciones}
                      name="Observaciones"
                      autoComplete="Observaciones"
                      value={Observaciones}
                      type="text"
                      placeholder={
                        !NoSoyPropietario
                          ? diccionario.Observaciones
                          : diccionario.placeholderObservaciones
                      }
                      onChange={changeValue}
                      error={NoSoyPropietario && erroresCamposRequeridos.Observaciones}
                    />
                    <br />
                    <Button
                      onClick={asistirJunta}
                      disabled={false}
                      size="large"
                      color="primary"
                      variant="contained"
                    >
                      {diccionario.asistirJunta}
                    </Button>
                    <br />
                    <br />
                  </div>
                </Paper>
              </Grid>
              <Box mt={40}>{/*<Copyright />*/}</Box>
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};

export default FormularioAsistenciaQR;
