import moment from "moment";
import { update } from "firebase/database";
import { insertaAsistente, cerrarSesionAsistente } from "app/views/juntas/FuncionesJuntas";

const loadJitsiScript = (despacho) => {
  let resolveLoadJitsiScriptPromise = null;
  const loadJitsiScriptPromise = new Promise((resolve) => {
    resolveLoadJitsiScriptPromise = resolve;
  });

  const script = document.createElement("script");

  var servidor = "c365juntas.comunidad365.es/";
  if (despacho.ServidorVideo && despacho.ServidorVideo !== "") {
    servidor = despacho.ServidorVideo;
  }

  script.src = "https://" + servidor + "/external_api.js#disableAEC:false";

  script.async = true;
  script.onload = () => resolveLoadJitsiScriptPromise(true);

  document.body.appendChild(script);

  return loadJitsiScriptPromise;
};

export const initialiseJitsi = async (
  firebase,
  despachoParam,
  idComunidad,
  state,
  setState,
  TituloJunta,
  NombreUsuario,
  idJunta = null,
  usuariosComunidad,
  NumeroJunta = null
) => {
  const despacho = despachoParam;
  if (!window.JitsiMeetExternalAPI) {
    await loadJitsiScript(despacho);
  }

  const comunidad = idComunidad;
  //const roomName = comunidad + despacho.Codigo + comunidad;
  /*
  const roomName = idJunta
    ? comunidad + despacho.Codigo + comunidad + idJunta
    : comunidad + despacho.Codigo + comunidad;
*/
  const roomName = idJunta ? comunidad + idJunta : comunidad;
  try {
    const options = {
      configOverwrite: {
        enableWelcomePage: true,
        defaultLanguage: "es",
        fileRecordingsEnabled: true,
        liveStreamingEnabled: true,
        startWithAudioMuted: false,
        startWithVideoMuted: false,
      },
      interfaceConfigOverwrite: {
        DEFAULT_BACKGROUND: "#1AC570",
        DEFAULT_LOCAL_DISPLAY_NAME: despacho.Nombre,
        TOOLBAR_BUTTONS: [
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          "fullscreen",
          "fodeviceselection",
          "profile",
          "chat",
          "recording",
          "livestreaming",
          "etherpad",
          "sharedvideo",
          "raisehand",
          "filmstrip",
          "feedback",
          "stats",
          "shortcuts",
          "tileview",
          "hangup",
          "videoquality",
          "settings",
          "help",
          "invite",
          "stats",
          "videobackgroundblur",
          "download",
          "mute-everyone",
          "e2ee",
          "security",
        ],

        SETTINGS_SECTIONS: ["devices", "language", "moderator", "profile", "calendar"],
      },
      parentNode: document.getElementById("jitsi-container-id"),
      roomName: roomName,

      jwt: despacho.TokenJitsi ? despacho.TokenJitsi : null,
      userInfo: {
        email: despacho.Correo,
        displayName: NombreUsuario,
      },
      width: "100%",
      height: "100%",
    };
    var servidor = "meet.comunidad365.es";
    if (despacho.ServidorVideo && despacho.ServidorVideo !== "") {
      servidor = despacho.ServidorVideo;
    }

    const _jitsi = new window.JitsiMeetExternalAPI(servidor, options);
    setState({ ...state, jitsi: _jitsi, openSesion: false });

    _jitsi.once("videoConferenceJoined", (response) => {
      _jitsi.executeCommand("setFollowMe", true);
      console.log("pruebecilla", response);
      const comu = state.comunidad;
      const comusList = state.comus;

      if (comu && comu !== "") {
        const comunidad = comusList.filter((com) => com.Id === comu.Id);
        const Junta = comunidad[0].Junta;

        if (!Junta.Estado) {
          try {
            if (idJunta === "null") {
              const comunidadUpdatear = firebase.getReferencia(`Comunidad/${idComunidad}/Junta`);
              update(comunidadUpdatear, {
                Estado: true,
              });
            } else {
              const comunidadUpdatear = firebase.getReferencia(
                `Comunidad/${idComunidad}/Juntas/${idJunta}`
              );
              update(comunidadUpdatear, {
                Estado: true,
              });
            }
          } catch (error) {
            console.log("Error:", error);
          }
        }
        setState({ ...state, dentro: true });
      }
    });
    _jitsi.once("videoConferenceLeft", (response) => {
      if (idJunta === "null") {
        const comunidadUpdatear = firebase.getReferencia(`Comunidad/${idComunidad}/Junta`);
        update(comunidadUpdatear, {
          Estado: false,
        });
      } else {
        const comunidadUpdatear = firebase.getReferencia(
          `Comunidad/${idComunidad}/Juntas/${idJunta}`
        );
        update(comunidadUpdatear, {
          Estado: false,
        });
      }
    });
    _jitsi.on("participantJoined", (response) => {
      var email = response.displayName;

      var usuariosSesion = state.usuariosSesion;
      //Primero vemos  si este usuario ya se había conectado
      const indexUsuario = usuariosSesion.findIndex((us) => us.Email === email);

      const usuario = usuariosComunidad.find((usu) => usu.email === email);
      var usu = {};

      const idSesionUsuario = response.id;
      insertaAsistente(firebase, idComunidad, idJunta, usuario, email, idSesionUsuario);

      if (indexUsuario > -1) {
        const fecha = new Date();
        usuariosSesion[indexUsuario].idCall = response.id;
        usuariosSesion[indexUsuario].Conexiones =
          Number(usuariosSesion[indexUsuario].Conexiones) + 1;

        usuariosSesion[indexUsuario].Entrada = moment(fecha).format("HH:mm:ss");
      } else {
        if (usuario) {
          const fecha = new Date();
          usu = {
            ...usuario,
            idCall: response.id,
            Salida: "",
            Tiempo: 0,
            Entrada: moment(fecha).format("HH:mm:ss"),
            Conexiones: 1,
          };
        } else {
          const fecha = new Date();
          usu = {
            Nombre: "Usuario no encontrado como vecino de la comunidad",
            idCall: response.id,
            Salida: "",
            Tiempo: "",
            Email: "",
            DNI: "",
            Entrada: moment(fecha).format("HH:mm:ss"),
            Conexiones: 1,
          };
        }
        usuariosSesion = usuariosSesion.concat(usu);
      }

      setState({ ...state, usuariosSesion: usuariosSesion });
    });
    _jitsi.on("participantLeft", (response) => {
      var usuariosSesion = state.usuariosSesion;

      const indexUsuario = usuariosSesion.findIndex((us) => us.idCall === response.id);

      let email = "";
      const usuario = usuariosComunidad.find((usu) => usu.email === email);

      cerrarSesionAsistente(firebase, idComunidad, idJunta, response.id);

      if (indexUsuario > -1) {
        const fecha = new Date();
        var lunch = moment(usuariosSesion[indexUsuario].Entrada, "HH:mm:ss");

        const Tiempo = moment().diff(lunch, "minutes");

        const tiempoAcumulado = Number(Tiempo) + Number(usuariosSesion[indexUsuario].Tiempo);
        usuariosSesion[indexUsuario].Tiempo = tiempoAcumulado;
        usuariosSesion[indexUsuario].Salida = moment(fecha).format("HH:mm:ss");
      }
      setState({ ...state, usuariosSesion: usuariosSesion });
    });
  } catch (error) {}
};
