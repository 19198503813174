import { get } from "firebase/database";

export const validarEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validarDNI = (dni) => {
  // Expresión regular para validar el formato del DNI
  const dniRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;

  // Comprobar si el DNI cumple con el formato
  if (!dniRegex.test(dni)) {
    return false;
  }

  // Obtener la letra del DNI (la última posición)
  const letras = "TRWAGMYFPDXBNJZSQVHLCKET";
  const letraCalculada = letras.charAt(parseInt(dni, 10) % 23);
  const letraDNI = dni.charAt(8).toUpperCase();

  // Comprobar si la letra del DNI es correcta
  if (letraDNI !== letraCalculada) {
    return false;
  }

  // Si pasa todas las validaciones, el DNI es válido
  return true;
};

// Function to check if the identifier corresponds to a company
const isCompanyIdentifier = (identifier) => {
  // Regular expression to match Spanish company identification numbers
  const companyRegex = /^[A-Z]{1}[0-9]{8}$/;
  return companyRegex.test(identifier);
};

// Function to check if the identifier corresponds to a person
const isPersonIdentifier = (identifier) => {
  // Regular expression to match Spanish personal identification numbers
  const personRegex = /^[0-9]{8}[A-Z]$/;
  return personRegex.test(identifier);
};

// Function to determine the type of identifier
export const tipoDNI = (identifier) => {
  if (isCompanyIdentifier(identifier)) {
    return "empresa";
  } else if (isPersonIdentifier(identifier)) {
    return "persona";
  } else {
    return "desconocido"; // If neither a company nor a person identifier
  }
};

export const yaExisteDNI = async (firebase, nombreComprobar, idPropio = null) => {
  const snapshot = await get(firebase.getReferencia("Usuarios", null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    childData.idInterno = childKey;
    datos.push(childData);
  });
  let otros = [];

  if (!idPropio) {
    otros = datos.filter(
      (usuarios) => (usuarios.DNI && usuarios.DNI.toLowerCase()) === nombreComprobar.toLowerCase()
    );
  } else {
    otros = datos.filter(
      (usuarios) =>
        usuarios.DNI &&
        usuarios.DNI.toLowerCase() === nombreComprobar.toLowerCase() &&
        idPropio != usuarios.idInterno
    );
  }

  return otros.length <= 0 ? false : true;
};

export const yaExisteEmail = async (firebase, nombreComprobar, idPropio = null) => {
  const snapshot = await get(firebase.getReferencia("Usuarios", null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    childData.idInterno = childKey;
    datos.push(childData);
  });
  let otros = null;

  if (!idPropio) {
    otros = datos.filter(
      (usuarios) =>
        (usuarios.Email && usuarios.Email.toLowerCase()) === nombreComprobar.toLowerCase()
    );
  } else {
    otros = datos.filter(
      (usuarios) =>
        usuarios.Email &&
        usuarios.Email.toLowerCase() === nombreComprobar.toLowerCase() &&
        idPropio != usuarios.idInterno
    );
  }

  return otros.length > 0 ? true : false;
};
