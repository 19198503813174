import * as ROUTES from "./constants/routes";
import { authRoles } from "./auth/authRoles";
import { lenguaje } from "../lenguajes/index";

const initialState = {
  diccionario: lenguaje.ESP.diccionario,
  lenguaje: lenguaje.ESP.lenguaje,
  traduccionTabla: lenguaje.ESP.traduccionTabla,
};

const diccionario = initialState.diccionario;

export const navigations = [
  { name: diccionario.Inicio, idMenu: "INICIO", path: ROUTES.HOME, icon: "casa" },
  {
    name: diccionario.Comunidades,
    idMenu: "COMUNIDADES",
    icon: "comunidad",
    path: ROUTES.LISTA_COMUNIDADES,
  },
  {
    name: diccionario.Incidencias,
    idMenu: "INCIDENCIAS",
    icon: "comunidad",
    path: ROUTES.LISTA_INCIDENCIAS,
  },
  { name: diccionario.Proveedores, idMenu: "PROVEEDORES", icon: "comunidad", path: ROUTES.GREMIOS },
  { name: diccionario.Roles, idMenu: "ROLES", icon: "comunidad", path: ROUTES.ROLES },
  {
    name: diccionario.Notificaciones,
    idMenu: "NOTIFICACIONES",
    icon: "usuario",
    path: ROUTES.NOTIFICACIONES,
  },
  { name: diccionario.Despacho, idMenu: "DESPACHO", icon: "comunidad", path: ROUTES.DESPACHO },
  /*
  {
    name: diccionario.UsuariosCongreso,
    idMenu: "CONGRESO",
    icon: "notificaciones",
    path: ROUTES.CONGRESO,
  },*/
];

export default navigations;
export function navigations2(diccionario) {
  return [
    { name: diccionario.Inicio, idMenu: "INICIO", path: ROUTES.HOME, icon: "casa" },
    {
      name: diccionario.Comunidades,
      idMenu: "COMUNIDADES",
      icon: "comunidad",
      path: ROUTES.LISTA_COMUNIDADES,
      otherRoutes: [
        ROUTES.DETALLE_COMUNIDAD,
        ROUTES.DOCUMENTOS,
        ROUTES.USUARIOS,
        ROUTES.DETALLE_USUARIO,
        ROUTES.REGISTRO_HORARIO,
        ROUTES.ACTIVIDADES,
        ROUTES.ANADIR_ACTIVIDAD,
        ROUTES.LISTA_VOTACIONES,
        ROUTES.DETALLE_VOTACION,
        ROUTES.ANADIR_VOTACION,
        ROUTES.LISTA_ZONASCOMUNES,
        ROUTES.ANADIR_ZONACOMUN,
        ROUTES.DETALLE_ZONACOMUN,
        ROUTES.HISTORIAL_ZONACOMUN,
        ROUTES.JUNTASVIRTUALES,
        ROUTES.ANADIR_JUNTA,
        ROUTES.DETALLE_JUNTA,
        ROUTES.DETALLE_ACTIVIDAD,
      ],
    },
    {
      name: diccionario.Incidencias,
      idMenu: "INCIDENCIAS",
      icon: "incidencias",
      path: ROUTES.LISTA_INCIDENCIAS,
      otherRoutes: [ROUTES.DETALLE_INCIDENCIA, ROUTES.ANADIR_INCIDENCIA],
    },
    {
      name: diccionario.Proveedores,
      idMenu: "PROVEEDORES",
      icon: "gremios",
      path: ROUTES.GREMIOS,
      otherRoutes: [ROUTES.DETALLE_GREMIO, ROUTES.ANADIR_GREMIO],
    },
    {
      name: diccionario.Roles,
      idMenu: "ROLES",
      icon: "roles",
      path: ROUTES.ROLES,
      otherRoutes: [ROUTES.DETALLE_ROL, ROUTES.ANADIR_ROL],
    },
    {
      name: diccionario.Notificaciones,
      idMenu: "NOTIFICACIONES",
      icon: "notificaciones",
      path: ROUTES.NOTIFICACIONES,
      otherRoutes: [ROUTES.NOTIFICACION_BUSCADOR, ROUTES.DETALLE_NOTIFICACION],
    },
    {
      name: diccionario.Despacho,
      idMenu: "DESPACHO",
      icon: "despacho",
      path: ROUTES.DESPACHO,
      otherRoutes: [
        ROUTES.CENTROS_DE_TRABAJO,
        ROUTES.DOCUMENTOS,
        ROUTES.DETALLE_DESPACHO,
        ROUTES.ANADIR_CENTRO_DE_TRABAJO,
        ROUTES.DETALLE_CENTRO_TRABAJO,
        ROUTES.ANADIR_EMPLEADO,
      ],
    },
/*
    {
      name: diccionario.UsuariosCongreso,
      idMenu: "CONGRESO",
      icon: "notificaciones",
      path: ROUTES.CONGRESO,
    },*/
  ];
}
