import { get } from "firebase/database";

export const getNombreCreadorGremio = (usuarios, idCreador) => {
  let strNombreCreador = "Conecta";
  const usuCreador =
    idCreador &&
    usuarios.filter((usuario) => {
      if (usuario.idInterno === idCreador) {
        strNombreCreador = usuario.Nombre + " " + usuario.Apellido;
      }
      return strNombreCreador;
    });

  return strNombreCreador;
};

export const traerComunidades = async (firebase) => {
  const snapshot = await get(firebase.getReferencia("Comunidad", null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    datos.push({
      label: childData.Nombre,
      value: childKey,
      Roles: [],
    });
  });
  return datos;
};
