import { get } from "firebase/database";

export const getNombreComunidad = async (firebase, idComunidad) => {
  const snapshot = await get(firebase.getReferencia(`Comunidad/${idComunidad}/Nombre`, null));
  const nombre = snapshot.val();
  return nombre;
};

export const getComunidad = async (firebase, idComunidad) => {
  const snapshot = await get(firebase.getReferencia(`Comunidad/${idComunidad}`, null));
  const comunidad = snapshot.val();
  return comunidad;
};

export const getComunidades = async (firebase) => {
  const comunidades = [];
  const snapshot = await get(firebase.getReferencia("Comunidad", null));
  snapshot.forEach((childSnapshot) => {
    comunidades[childSnapshot.key] = childSnapshot.val().Nombre;
  });

  console.log(comunidades);
  return comunidades;
};
