import { get, push, update } from "firebase/database";
import * as ROUTES from "../../constants/routes";
import moment from "moment";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { getDireccionCompleta } from "app/components/helpers/Various";
import { loadAdminUsers } from "app/views/adminDesk/FuncionesAdmin";

export const getListaTiposAsistencia = (diccionario) => {
  return [
    /* { label: diccionario["ASISTENCIA_REMOTA_APP"], value: "REMOTA_APP", isDisabled:true },
    { label: diccionario["ASISTENCIA_REMOTA_APP_DELEGADO"], value: "REMOTA_APP_DELEGADO", isDisabled:true },
    { label: diccionario["ASISTENCIA_REMOTA_ENLACE_VIDEO"], value: "REMOTA_ENLACE_VIDEO", isDisabled:true },*/
    { label: diccionario["ASISTENCIA_FISICA"], value: "FISICA", isDisabled: false },
    { label: diccionario["ASISTENCIA_DELEGADA"], value: "DELEGADA", isDisabled: false },
    { label: diccionario["ASISTENCIA_FISICA_QR"], value: "FISICA_QR", isDisabled: true },
    {
      label: diccionario["ASISTENCIA_FISICA_FORMULARIO"],
      value: "FISICA_FORMULARIO",
      isDisabled: true,
    },
    {
      label: diccionario["ASISTENCIA_FISICA_FORMULARIO_NO_PROPIETARIO"],
      value: "FISICA_FORMULARIO_NO_PROPIETARIO",
      isDisabled: true,
    },

    { label: diccionario["ASISTENCIA_"], value: "", isDisabled: false },
  ];
};

export const openNewTabJitsi = (e, IdComu, IdJunta = null, tituloJunta = "", numeroJunta = "") => {
  const routeVideoJitsi = ROUTES.VIDEO_JITSI;
  e.preventDefault();
  const newTabUrl = `${window.location.origin}${routeVideoJitsi}/${IdComu}/${IdJunta}/${numeroJunta}`; // Replace 'special-route' with your desired route
  window.open(newTabUrl, "_blank", "noopener,noreferrer");
};

export const cerrarSesionAsistente = async (firebase, idComunidad, idJunta, idSesionUsuario) => {
  const refAsistentes = firebase.getReferencia(
    `Comunidad/${idComunidad}/Juntas/${idJunta}/Asistentes`,
    null
  );

  const snapshot = await get(refAsistentes);
  const asistentes = snapshot.val();
  let datosAsistentes = [];
  datosAsistentes =
    asistentes &&
    Object.keys(asistentes).map((key) => ({
      idInterno: key,
      ...asistentes[key],
    }));
  // Busco por id de sesión
  const asistenteEnJunta = datosAsistentes
    ? datosAsistentes.filter((asistente) => asistente.idSesionUsuario === idSesionUsuario)
    : [];
  if (asistenteEnJunta.length > 0) {
    // Lo modifico???
    asistenteEnJunta.map((asist) => {
      const horaEntrada = moment(asist.Entrada);
      const cierre = Date.now();
      const horaCierre = moment(cierre);
      const Tiempo = horaCierre.diff(horaEntrada, "minutes");
      const tiempoAcumulado = Number(Tiempo) + Number(asist.TiempoAcumulado);

      const refAsistenteMod = firebase.getReferencia(
        `Comunidad/${idComunidad}/Juntas/${idJunta}/Asistentes/${asist.idInterno}`,
        null
      );
      const objAsistenteMod = {
        TiempoAcumulado: tiempoAcumulado,
        Salida: cierre,
      };

      update(refAsistenteMod, objAsistenteMod);
    });
  }
};
export const insertaAsistente = async (
  firebase,
  idComunidad,
  idJunta,
  usuario,
  nombreEnVideoConferencia = "",
  idSesionUsuario
) => {
  const refAsistentes = firebase.getReferencia(
    `Comunidad/${idComunidad}/Juntas/${idJunta}/Asistentes`,
    null
  );

  const snapshot = await get(refAsistentes);
  const asistentes = snapshot.val();
  let datosAsistentes = [];
  datosAsistentes =
    asistentes &&
    Object.keys(asistentes).map((key) => ({
      idInterno: key,
      ...asistentes[key],
    }));

  if (usuario) {
    const idUsuario = usuario.idInterno;

    const asistenteEnJunta = datosAsistentes
      ? datosAsistentes.filter((asistente) => asistente.DNI === idUsuario)
      : [];

    if (asistenteEnJunta.length > 0) {
      // Modifico
      asistenteEnJunta.map((asist) => {
        const refAsistenteMod = firebase.getReferencia(
          `Comunidad/${idComunidad}/Juntas/${idJunta}/Asistentes/${asist.idInterno}`,
          null
        );
        const objAsistenteMod = {
          idSesionUsuario: idSesionUsuario,
          Nombre: usuario.nombre,
          Apellido: usuario.apellido,
          DNI: usuario.DNI,
          Email: usuario.email,
          Direcciones: usuario.direcciones ? usuario.direcciones : [],
          TiempoAcumulado: asist.TiempoAcumulado,
          TipoAsistencia: "REMOTA_ENLACE_VIDEO",
          Entrada: Date.now(),
          Salida: "",
          Conexiones: asist.Conexiones ? asist.Conexiones + 1 : 1,
        };
        update(refAsistenteMod, objAsistenteMod);
      });
    } else {
      // Lo inserto
      const objAsistente = {
        idSesionUsuario: idSesionUsuario,
        Nombre: usuario.nombre,
        Apellido: usuario.apellido,
        DNI: usuario.DNI,
        Email: usuario.email,
        Direcciones: usuario.direcciones ? usuario.direcciones : [],
        Observaciones: "",
        TiempoAcumulado: 0,
        TipoAsistencia: "REMOTA_ENLACE_VIDEO",
        Entrada: Date.now(),
        Salida: "",
        Conexiones: 1,
      };
      push(refAsistentes, objAsistente);
    }
  } else {
    const asistenteEnJunta = datosAsistentes
      ? datosAsistentes.filter(
          (asistente) =>
            asistente.Email === nombreEnVideoConferencia ||
            asistente.Nombre === nombreEnVideoConferencia
        )
      : [];

    if (asistenteEnJunta.length > 0) {
      // Modifico
      asistenteEnJunta.map((asist) => {
        const refAsistenteMod = firebase.getReferencia(
          `Comunidad/${idComunidad}/Juntas/${idJunta}/Asistentes/${asist.idInterno}`,
          null
        );
        const objAsistenteMod = {
          idSesionUsuario: idSesionUsuario,
          Nombre: nombreEnVideoConferencia,
          Apellido: "",
          DNI: nombreEnVideoConferencia,
          Email: nombreEnVideoConferencia,
          Entrada: Date.now(),
          Salida: "",
          Conexiones: asist.Conexiones ? asist.Conexiones + 1 : 1,
        };
        update(refAsistenteMod, objAsistenteMod);
      });
    } else {
      // Lo inserto
      const objAsistente = {
        idSesionUsuario: idSesionUsuario,
        Nombre: nombreEnVideoConferencia,
        Apellido: "",
        DNI: "",
        Email: "",
        Direcciones: [],
        Observaciones: "Usuario sin registrar",
        TiempoAcumulado: 0,
        TipoAsistencia: "REMOTA_ENLACE_VIDEO",
        Entrada: Date.now(),
        Salida: "",
        Conexiones: 1,
      };
      push(refAsistentes, objAsistente);
    }
  }
};

export const getTituloJunta = async (firebase, idComunidad, idJunta) => {
  const snapshot = await get(
    firebase.getReferencia(`Comunidad/${idComunidad}/Juntas/${idJunta}/Titulo`, null)
  );
  const Tit = snapshot.val();
  return Tit;
};

export const getJunta = async (firebase, idComunidad, idJunta) => {
  const snapshot = await get(
    firebase.getReferencia(`Comunidad/${idComunidad}/Juntas/${idJunta}`, null)
  );
  const Junta = snapshot.val();
  return Junta;
};

const normalizeString = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const yaExisteNumero = async (firebase, idComunidad, numeroComprobar, idPropio = null) => {
  const snapshot = await get(firebase.getReferencia(`Comunidad/${idComunidad}/Juntas`, null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    childData.idInterno = childKey;
    datos.push(childData);
  });

  let otros = null;
  if (!idPropio) {
    otros = datos.filter(
      (junta) =>
        normalizeString(junta.Numero.toLowerCase()) ===
        normalizeString(numeroComprobar.toLowerCase())
    );
  } else {
    otros = datos.filter(
      (junta) =>
        normalizeString(junta.Numero.toLowerCase()) ===
          normalizeString(numeroComprobar.toLowerCase()) && idPropio != junta.idInterno
    );
  }

  return otros.length > 0 ? true : false;
};
export const formatFecha = (fecha) => {
  let retFecha = "";
  if (fecha) {
    fecha = new Date(fecha).toLocaleDateString("en-GB").replace(/\//g, "-");
  }
  return fecha;
};

export const cargarOpcionesJuntasComunidad = async (firebase, IdComunidad) => {
  try {
    const snapshot = await get(firebase.getReferencia(`Comunidad/${IdComunidad}/Juntas`, null));
    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      let childData = childSnapshot.val();

      childData.idInterno = childKey;

      if (!childData.Borrado) {
        if (!IdComunidad || (IdComunidad && childData.Comunidad === IdComunidad)) {
          datos.push({
            label: childData.Numero + " - " + childData.Titulo,
            value: childKey,
          });
        }
      }
    });
    return datos;
  } catch (error) {
    console.error("Error al cargar las Juntas", error);
  }
};

export const cargarJuntasComunidad = async (firebase, IdComunidad) => {
  try {
    const snapshot = await get(firebase.getReferencia(`Comunidad/${IdComunidad}/Juntas`, null));
    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      let childData = childSnapshot.val();
      childData.FechaCelebracionFormateada = formatFecha(childData.FechaCelebracion);
      childData.NumeroAsistentes = childData.Asistentes
        ? Object.keys(childData.Asistentes).length
        : 0;
      childData.idInterno = childKey;

      if (!childData.Borrado) {
        if (!IdComunidad || (IdComunidad && childData.Comunidad === IdComunidad)) {
          datos.push(childData);
        }
      }
    });
    return datos;
  } catch (error) {
    console.error("Error al cargar las Juntas", error);
  }
};

export const yaExisteTitulo = async (firebase, idComunidad, tituloComprobar, idPropio = null) => {
  const snapshot = await get(firebase.getReferencia(`Comunidad/${idComunidad}/Juntas`, null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    childData.idInterno = childKey;
    datos.push(childData);
  });

  let otros = null;
  if (!idPropio) {
    otros = datos.filter(
      (junta) =>
        normalizeString(junta.Titulo.toLowerCase()) ===
        normalizeString(tituloComprobar.toLowerCase())
    );
  } else {
    otros = datos.filter(
      (junta) =>
        normalizeString(junta.Titulo.toLowerCase()) ===
          normalizeString(tituloComprobar.toLowerCase()) && idPropio != junta.idInterno
    );
  }

  return otros.length > 0 ? true : false;
};

export const generateWordDocument = (title, content) => {
  // Create a new document
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: title,
                bold: true,
                size: 32, // 16 * 2 (in half-points)
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: content,
                size: 24, // 12 * 2 (in half-points)
              }),
            ],
          }),
        ],
      },
    ],
  });

  // Convert the document to a Blob
  return Packer.toBlob(doc);
};

export const cargarRoles = async (firebase, setListaRoles) => {
  try {
    const snapshot = await get(firebase.getReferencia("Roles2", null));
    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      childData.idInterno = childKey;
      datos.push({
        label: childData.Nombre,
        value: childData.Nombre,
      });
    });
    setListaRoles(datos);
  } catch (error) {}
};
export const traerPropietariosComunidad = async (firebase, idComunidad = null) => {
  try {
    const snapshot = await get(firebase.getReferencia("Usuarios", null));

    const dirRef = idComunidad ? `Comunidad/${idComunidad}` : "Comunidad";
    const snapshotComunidades = await get(firebase.getReferencia(dirRef));
    let comuComus = [];

    if (!idComunidad) {
      snapshotComunidades.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        let childData = childSnapshot.val();
        childData.idInterno = childKey;
        comuComus.push(childData);
      });
    } else {
      let childData = snapshotComunidades.val();
      childData.idInterno = idComunidad;
      comuComus.push(childData);
    }

    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      childData.idInterno = childKey;

      const nombreProp =
        childData.Roles && childData.Roles.length > 0
          ? childData.Nombre + " " + childData.Apellido + " " + " | " + childData.Roles.join(",")
          : childData.Nombre + " " + childData.Apellido;
      let strCompDirec = "";
      let arrDirecciones = [];
      let arrStrDirecciones = [];
      if (idComunidad) {
        const instancias = comuComus[0]["Instancias"] && comuComus[0]["Instancias"];

        arrDirecciones =
          Array.isArray(instancias) &&
          instancias.filter(
            (instancia) => instancia.Usuario === childKey && instancia.Tipo !== "empleadocomunidad"
          );
        Array.isArray(arrDirecciones) &&
          arrDirecciones.map((direcc) => {
            arrStrDirecciones.push(getDireccionCompleta(direcc));
          });
        arrStrDirecciones = arrStrDirecciones.filter((element) => element !== "");
        strCompDirec = arrStrDirecciones.length > 0 ? arrStrDirecciones.join(", ") : "";
      } else {
        // Si no nos llega idComunidad tendré que recorrer todas a las que pertenezca el usuario
        let instanciasMergeadas = [];
        comuComus.map((comu) => {
          const insts = comu.Instancias && comu.Instancias;

          arrDirecciones =
            Array.isArray(insts) &&
            insts.filter(
              (instancia) =>
                instancia.Usuario === childKey && instancia.Tipo !== "empleadocomunidad"
            );
          instanciasMergeadas = instanciasMergeadas.concat(arrDirecciones);
        });
        arrDirecciones = instanciasMergeadas;
        Array.isArray(arrDirecciones) &&
          arrDirecciones.map((direcc) => {
            arrStrDirecciones.push(getDireccionCompleta(direcc));
          });

        arrStrDirecciones = arrStrDirecciones.filter((element) => element !== "");
        strCompDirec = arrStrDirecciones.length > 0 ? arrStrDirecciones.join(", ") : "";
      }

      if (childData.Administrador !== true && childData.Borrado !== true) {
        if (
          idComunidad &&
          childData.Comunidades &&
          Object.keys(childData.Comunidades).includes(idComunidad)
        ) {
          datos.push({
            label: nombreProp + " | " + strCompDirec,
            value: childKey,
            nombre: childData.Nombre,
            Nombre: childData.Nombre,
            DNI: childData.DNI,
            apellido: childData.Apellido,
            Apellido: childData.Apellido,
            idInterno: childData.idInterno,
            direcciones: arrDirecciones,
            Direcciones: arrDirecciones,
            email: childData.Email,
            Email: childData.Email,
            Roles: childData.Comunidades[idComunidad] && childData.Comunidades[idComunidad].Roles,
          });
        } else if (!idComunidad) {
          datos.push({
            label: nombreProp + " | " + strCompDirec,
            value: childKey,
            nombre: childData.Nombre,
            Nombre: childData.Nombre,
            DNI: childData.DNI,
            apellido: childData.Apellido,
            Apellido: childData.Apellido,
            idInterno: childData.idInterno,
            direcciones: arrDirecciones,
            Direcciones: arrDirecciones,
            email: childData.Email,
            Email: childData.Email,
            Roles: childData.Comunidades[idComunidad] && childData.Comunidades[idComunidad].Roles,
          });
        }
      }
    });

    return datos;
  } catch (error) {
    console.error("Error al cargar usuarios", error);
  }
};

export const traerAdministradoresComunidad = async (firebase, idComunidad = null) => {
  let adminUsers = null;
  try {
    adminUsers = await loadAdminUsers(firebase);
    // Filtro los que tengan perfil de admon
    adminUsers = adminUsers.filter((usr) => usr.PerfilEmpleadoOficina === "Administrador");
    return adminUsers;
  } catch (error) {
    console.error("Error al cargar usuarios", error);
  }
};

export const traerImportantesComunidad = async (firebase, idComunidad) => {
  try {
    const propietarios = await traerPropietariosComunidad(firebase, idComunidad);

    const presidentes = propietarios.filter(
      (usr) => usr.Roles && Array.isArray(usr.Roles) && usr.Roles.includes("Presidente")
    );
    const vicepresidentes = propietarios.filter(
      (usr) => usr.Roles && Array.isArray(usr.Roles) && usr.Roles.includes("Vicepresidente")
    );
    const vocales = propietarios.filter(
      (usr) => usr.Roles && Array.isArray(usr.Roles) && usr.Roles.includes("Vocal")
    );

    return {
      presidentes: presidentes,
      vicepresidentes: vicepresidentes,
      vocales: vocales,
    };
  } catch (error) {
    console.error("Error al cargar usuarios", error);
  }
};

export const getListaTipos = (diccionario) => {
  return [
    { label: diccionario.ORDINARIA, value: "ORDINARIA" },
    { label: diccionario.EXTRAORDINARIA, value: "EXTRAORDINARIA" },
  ];
};
